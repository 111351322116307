/* eslint-disable react/prop-types */
import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { HashRouter } from 'react-router-dom';
import { SwanConfiguration } from './src/components/shared/SwanConfiguration';

// Ignoring prop type validation requirement for extending Gatsby wrapRootElement api.
// eslint-disable-next-line react/prop-types
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
  props,
}: any) => (
  <HashRouter hashType="noslash">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SwanConfiguration {...props}>{element}</SwanConfiguration>
  </HashRouter>
);
